import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Hook to get route params
import { getProduct } from './ProductService'; // API function to fetch product by ID
import { Container, Typography, Box, CardMedia } from '@mui/material';

const ProductDetails = () => {
  const { id } = useParams(); // Get the product ID from the URL
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getProduct(id)
      .then((response) => {
        setProduct(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching product details:', error);
        setError('Failed to load product details.');
        setLoading(false);
      });
  }, [id]); // Dependency array includes id so useEffect runs when id changes

  if (loading) {
    return <Typography variant="h5" component="div">Loading product details...</Typography>;
  }

  if (error) {
    return <Typography variant="h5" component="div" color="error">{error}</Typography>;
  }

  return (
    <Box sx={{  backgroundColor:"#FF4191", width:"100%", height:{ xs:"100%", sm:"100vh" ,md:"100vh"} }}>
      <Box sx={{  backgroundColor:"#FF4191", width:"100%", maxheight:{ xs:"100vh", sm:"100%" ,md:"100vh"} }}>
        <Container maxWidth="xl">
              {product && (
                <Box sx={{ pt:"50px" }}>
                  <Typography variant="h3" gutterBottom>
                    {product.title}
                  </Typography>
                  
                  <Box sx={{ mt: 3, display:"flex", flexDirection:{xs:"column", sm:"column",md:"row"}}}>
                    <Box sx={{ width: {xs:"100%", sm:"100%",md:"50%"}, objectFit: "cover" }}>
                        <CardMedia
                            component="img"
                            sx={{ width: "100%", height: "auto" }}
                            image={`https://backend.thanikadakollokello.com/storage/app/public/${product.image}`}
                            // Assuming product has an `image_url` field
                            alt={product.name}
                            
                        />
                    </Box>
                    <Box sx={{width: {xs:"100%", sm:"100%",md:"50%"}, ml: {xs:"0px", sm:"0px",md:"40px"}, mt: {xs:"40px", sm:"40px",md:"0px"}, paddingBottom:"100px" }}>
                        <Typography variant="p" sx={{ fontSize:"20px"}} gutterBottom>
                            {product.description}
                        </Typography>    
                    </Box>
                    
                  </Box>
                </Box>
              )}
        </Container>
      </Box>
    </Box>
    
    
  );
};

export default ProductDetails;
