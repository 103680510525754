// src/components/ProductForm.js
import React, { useEffect, useState } from 'react';
import { createProduct, updateProduct } from '../ProductService';
import { TextField, Button, Box, Typography, Paper, Grid } from '@mui/material';
import {  Card, CardMedia, CardContent, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { getProducts, deleteProduct } from '../ProductService';

const ProductForm = ({ product, onSubmit }) => {
    const [title, setTitle] = useState(product ? product.title : '');
    const [description, setDescription] = useState(product ? product.description : '');
    const [image, setImage] = useState(null);
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = () => {
        getProducts()
            .then(response => setProducts(response.data))
            .catch(error => console.error('There was an error!', error));
    };

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this product?')) {
            deleteProduct(id)
                .then(() => fetchProducts())
                .catch(error => console.error('Error deleting product', error));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        if (image) {
            formData.append('image', image);
        }

        const handleForm = product
            ? updateProduct(product.id, formData).then(onSubmit)
            : createProduct(formData).then(onSubmit);

        handleForm
            .then(() => navigate('/'))
            .catch((err) => console.error('Error handling form', err));
    };

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={8} md={6}>
                    <Paper elevation={3} sx={{ p: 4 }}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            {product ? 'Edit Product' : 'Create Product'}
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                label="Title"
                                variant="outlined"
                                fullWidth
                                required
                                margin="normal"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <TextField
                                label="Description"
                                variant="outlined"
                                fullWidth
                                required
                                multiline
                                rows={4}
                                margin="normal"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <input
                                accept="image/*"
                                type="file"
                                onChange={(e) => setImage(e.target.files[0])}
                                style={{ margin: '10px 0' }}
                            />
                            <Button variant="contained" color="primary" type="submit" fullWidth>
                                {product ? 'Update' : 'Create'} Product
                            </Button>
                        </form>
                    </Paper>
                </Grid>
            </Grid>

                <Box sx={{ flexGrow: 1, p: 2 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Product List
                </Typography>
                <Grid container spacing={3}>
                    {products.map((product) => (
                        <Grid item xs={12} sm={6} md={4} key={product.id}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={`https://backend.thanikadakollokello.com/storage/app/public/${product.image}`}
                                    alt={product.title}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {product.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {product.description}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button 
                                        variant="contained" 
                                        color="secondary" 
                                        startIcon={<DeleteIcon />} 
                                        onClick={() => handleDelete(product.id)}
                                    >
                                        Delete
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default ProductForm;

