// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container } from '@mui/material';
import ProductList from './components/ProductList';
import ProductForm from './components/ProductForm';
import Home from './Home'; // Ensure Home component is correctly imported
import ProductDetails from './ProductDetails';

function App() {
    return (
        <Router>
            {/* <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Product Management
                    </Typography>
                    <Button color="inherit" component={Link} to="/">
                        Home
                    </Button>
                    <Button color="inherit" component={Link} to="/products">
                        Product List
                    </Button>
                    <Button color="inherit" component={Link} to="/admin/add-product">
                        Create Product
                    </Button>
                </Toolbar>
            </AppBar> */}
          
                <Routes>
                    <Route path="/" element={<Home />} /> {/* Home route */}
                    <Route path="/products" element={<ProductList />} /> {/* Product List route */}
                    <Route path="/admin/add-product" element={<ProductForm />} /> {/* Create Product route */}
                    <Route path="/product/:id" element={<ProductDetails />} />
                </Routes>
            
        </Router>
    );
}

export default App;
