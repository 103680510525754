import React, { useEffect, useState } from 'react';
import { Button, Container, Typography, Box, Grid, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import { getProducts } from './ProductService'; // Import your API functions
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook for navigation
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);


  // Fetch product data from backend using the getProducts API function
  useEffect(() => {
    getProducts()
      .then((response) => {
        setProducts(response.data); // Assuming the API returns an array of products
        setFilteredProducts(response.data); // Initially display all products
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching product data:', error);
        setError('Failed to load products.');
        setLoading(false);
      });
  }, []);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    
    // Filter products based on search term
    const filtered = products.filter((product) =>
      product.description.toLowerCase().includes(searchValue)
    );
    setFilteredProducts(filtered);
  };

  const handleShareClick = (id) => {
    navigate(`/product/${id}`); // Navigate to product details page with the product ID
  };

  // Function to render a single card
  const renderCard = (product, index) => (
    <Card sx={{ maxWidth: 345 }} key={index}>
      <CardActionArea>
          <CardMedia
            component="img"
            sx={{ height: 300, objectFit: 'cover', width: '400px' }}
            image={`https://backend.thanikadakollokello.com/storage/app/public/${product.image}`}
            // Assuming product has an `image_url` field
            alt={product.name}
          />
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
            {product.title}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2, // Limits text to 2 lines
    textOverflow: 'ellipsis',
  }}>
            {product.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={() => handleShareClick(product.id)}> 
      View
        </Button>
      </CardActions>
     
    </Card>
  );

  return (
    <Box sx={{backgroundColor:"#FF4191", pb:"50px", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      {/* Responsive Header */}
      <Box
        sx={{
          width: '100%',
          height: { xs: '20vh', sm: '15vh', md: '20vh' }, // Responsive height
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection:"column",
          backgroundColor: '#E72929',
           // Responsive padding
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.4rem', sm: '2rem', md: '3rem' }, // Responsive font size
            textAlign: 'center',
            color: '#D4F6FF',
            marginTop:"20px",
            fontWeight:"bold"
          }}
        >
          Welcome To Thanikada<span style={{color:'black'}}>Ko</span>lloKello.com
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '0.6rem', sm: '1.1rem', md: '1.5rem' }, // Responsive font size
            textAlign: 'center',
            color: '#fff',
            marginTop:"20px"
          }}
        >
          ලංකාවෙ ඉන්න අවංක හිතහොඳම කොල්ලො කෙල්ලො ටිකක් තමයි මේකෙ ඉන්නෙ
        </Typography>
      </Box>

      {/* Search Bar */}
      {/* <Box sx={{ width: {xs:'160%', sm:'100%', md:'100%',lg:'100%'}, mt: 3, display: 'flex', justifyContent: 'center' }}>
        <TextField
          label="Search by place"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ width: '50%' }}
        />
      </Box> */}

      {/* Main content with container */}
      <Container maxWidth="xl" sx={{ mt: 5 }}>
        {loading ? (
          <Typography variant="h5" component="div" textAlign="center">
            Loading products...
          </Typography>
        ) : error ? (
          <Typography variant="h5" component="div" color="error" textAlign="center">
            {error}
          </Typography>
        ) : (
          <Grid container spacing={4}>
            {Array.isArray(filteredProducts) && filteredProducts?.map((product, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                {renderCard(product, index)} {/* Render card component */}
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default Home;
