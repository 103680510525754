// src/ProductService.js
import axios from 'axios';

const API_URL = 'https://backend.thanikadakollokello.com/api/products'; // Update with your Laravel API endpoint

const getProducts = () => {
    return axios.get(API_URL);
};

const getProduct = (id) => {
    return axios.get(`${API_URL}/${id}`);
};

const createProduct = (formData) => {
    return axios.post(API_URL, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};

const updateProduct = (id, formData) => {
    return axios.post(`${API_URL}/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};

const deleteProduct = (id) => {
    return axios.delete(`${API_URL}/${id}`);
};

export { getProducts, getProduct, createProduct, updateProduct, deleteProduct };
