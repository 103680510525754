// src/components/ProductList.js
import React, { useEffect, useState } from 'react';
import { getProducts, deleteProduct } from '../ProductService';
import { Grid, Card, CardMedia, CardContent, CardActions, Typography, Button, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ProductList = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = () => {
        getProducts()
            .then(response => setProducts(response.data))
            .catch(error => console.error('There was an error!', error));
    };

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this product?')) {
            deleteProduct(id)
                .then(() => fetchProducts())
                .catch(error => console.error('Error deleting product', error));
        }
    };

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Product List
            </Typography>
            <Grid container spacing={3}>
                {products.map((product) => (
                    <Grid item xs={12} sm={6} md={4} key={product.id}>
                        <Card>
                            <CardMedia
                                component="img"
                                height="200"
                                image={`https://localhost:8000/storage/app/public/${product.image}`}
                                alt={product.title}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {product.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {product.description}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button 
                                    variant="contained" 
                                    color="secondary" 
                                    startIcon={<DeleteIcon />} 
                                    onClick={() => handleDelete(product.id)}
                                >
                                    Delete
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ProductList;
